<template>
  <div class="mt-8">
     <div class=" bg-white rounded-xl  p-2">
      <div class="">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Category...`"/>
          </div>
          <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Category'"/>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="categoryList.length > 0">
            <div v-for="(data, index) in categoryList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.itemCategoryMasterId)">
              <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <p class="text-text1 font-bold">{{data.categoryName}}</p>
                <p class="text-gray4 text" v-if="data.parentCategoryName !== ''">/{{data.parentCategoryName}}</p>
                </div>
            </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>  
      </div>
     </div>
     <addNewCatogery v-if="addCategory" :catId="selectedCatId" />
   </div>
</template>
<script>
import addNewCatogery from './addNewCatogery.vue'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    addNewCatogery,
    SearchTextInput,
    NoRecords,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Email', icon: '', sortName: 'emailAddress'},
        {title: 'Contact', icon: '', sortName: 'phoneNumber'},
        {title: 'Added Date', icon: 'DESC', sortName: 'addedDate'},
        {title: 'Status', icon: '', sortName: 'statusName'}
      ],
      categoryList: [],
      searchCategory: '',
      getListDebounce: null,
      resetPaginationValues: false,
      addCategory: false,
      selectedCatId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'addedDate',
        statusList: []
      },
    };
  },
  created() {},
  mounted() {
    this.getCategoryList()
     this.getListDebounce = this.debounce(function () {
      this.getCategoryList()
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("closeDialog", (response) => {
      console.log(response);
      if (response) {
        this.getCategoryList()
        this.addCategory = false
      }
      this.addCategory = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
    this.$root.$off('closeDialog')
  },
  methods: {
    openDetail (Id) {
      this.selectedCatId = Id
      this.addCategory= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.addCategory= true
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getCategoryList(true)
    },
    searchCall (data) {
      this.searchCategory = data
      this.getListDebounce()
    },
    getCategoryList (showlistLoader) {
      if (showlistLoader && this.searchCategory !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchCategory === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.getSettingCategoryList(
          0,
          20,
          this.searchCategory,
          '',
          '',
          response => {
            this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
};
</script>
<style scoped>
</style>