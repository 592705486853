<template>
  <div class="mt-8">
    <div class="card bg-white rounded-xl p-2">
      <div class="">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Item...`" />
          </div>
          <Button @buttonAction="addNewItems()" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'"
            :btnText="'Add Item'" />
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="column_size uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(0)">{{ titleObj[0].title }}</span>
              <div v-if="titleObj[0].icon !== ''">
                <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
            </div>
            <div class="column_size uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(1)">{{ titleObj[1].title }}</span>
              <div v-if="titleObj[1].icon !== ''">
                <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
            </div>
            <div class="column_size uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(2)">{{ titleObj[2].title }}</span>
              <div v-if="titleObj[2].icon !== ''">
                <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
            </div>
            <div class="column_size uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(3)">{{ titleObj[3].title }}</span>
              <div v-if="titleObj[3].icon !== ''">
                <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
              </div>
            </div>
          </div>
          <div v-if="leadList.length > 0">
            <div v-for="(data, index) in leadList" :key="index"
              class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer"
              @click="redirectToDetail(data.itemMasterId)">
              <div class="column_size  ">
                <div class="">
                  <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                  <p class="  text-text1 font-bold">{{ data.itemName }}</p>
                  <p class="  text-gray4 text">{{ data.itemCode }}</p>
                </div>
              </div>
              <div class="column_size  ">
                <div class="flex items-center">
                  <span class="  text-gray4 heading-6">{{ data.categoryName === '' ? '-' : data.categoryName }}</span>
                </div>
              </div>
              <div class="column_size">
                <div class="flex items-center">
                  <span class="  text-gray4 heading-6">{{ data.itemUnit === '' ? '-' : data.itemUnit }}</span>
                </div>
              </div>
              <div class="column_size  ">
                <div class="flex items-center">
                  <span class="  text-gray4 heading-6">{{ data.itemPrice | amountOnlyNumber }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
            <Pagination :totalDataCount="totalLeadCount" :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getLeadDataFromPagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
export default {
  name: "lead",
  components: {
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        { title: 'Item Name', icon: '', sortName: 'itemName' },
        { title: 'Category', icon: '', sortName: 'categoryName' },
        { title: 'Unit', icon: '', sortName: 'itemUnit' },
        { title: 'Price', icon: '', sortName: 'itemPrice' },
      ],
      statusList: [],
      leadList: [],
      searchForLead: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalLeadCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: '',
        statusList: []
      },
    };
  },
  created() { },
  mounted() {
    this.getLeadList()
    this.getLeadListDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getLeadList()
      }
      this.addLead = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    addNewItems() {
      this.$router.push({ name: 'SettingItemAdd', params: { itemId: 0 } })
    },
    getLeadDataFromPagination(data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit

      this.getLeadList(true)
    },
    sortByColumn(index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getLeadList(true)
    },
    redirectToDetail(id) {
      this.$router.push({ name: 'SettingItemDetails', params: { itemId: id } })
    },
    searchCall(data) {
      this.searchForLead = data
      this.getLeadListDebounce()
    },
    getLeadList(showlistLoader) {
      if (showlistLoader && this.searchForLead !== '') {
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      }
      if (this.searchForLead === '') {
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' })
      }
      MyJobApp.getItemsList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForLead,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        true,
        0,
        true,
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map((data) => {
              tempList.push({ ...data, qnty: 0 })
            })
          }
          this.leadList = tempList
          this.totalLeadCount = response.Data.count
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }
      )
    }
  }
};
</script>
<style scoped>
.column_size {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>