<template>
  <div class="popup_overlay px-4 relative ">
  <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;overflow: initial;">
    <div class="relative rounded-xl">
      <div>
        <div class="bg-primary flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
              {{catId > 0 ? 'Edit Category' : 'Add Category'}}
          </div>
          <div v-if="catId > 0">
            <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirm = true">
              <i class="fa-solid fa-trash"></i>
            </div>
          </div>
        </div>
        <div class="p-4">
          <div class="">
            <div class="mb-4">
              <TextField
                :inputId="'cusfirstnameid'"
                :textMaxlength="50"
                :showcharLimit="true"
                :inputext="sendObj.categoryName"
                :fieldError="isErr !== ''"
                :placholderText="`Category Name`"
                :lableText="'Category Name'"
                :autoFocus="true"
                @keyPressAction="isErr = ''"
                @inputChangeAction="(data) => sendObj.categoryName = data"  />
                <p v-if="isErr !== ''" class="text-error heading-7">{{isErr}}</p>
             </div>
            <div class=" mb-4">
              <div class="">
                <Dropdown
                  :inputext="sendObj.parentCategoryName"
                  :inputId="'categorySel2'"
                  :lableText="'Category'"
                  :placholderText="`Select Category`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showClearIcon ="true"
                  :showPlus="false"
                  :keyName="'categoryName'"
                  :listId="'itemCategoryMasterId'"
                  :items="categoryList"
                  @inputChangeAction="callsearchCust"
                  @selectNewForVal="selectCatValue"
                />
              </div>
             </div>
          </div>
        </div>
      </div>
        <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveNewCustomer()"/>
          </div>
          </div>
      </div>
  </div>
  <ConfirmBox v-if="removeConfirm" :message="'After deleting this category all items move to generate category and all child category will we separate main category.'" :title="'Are you sure?'" />
</div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Dropdown from '@/View/components/dropdown.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['catId'],
components: {
  Button,
  ConfirmBox,
  Dropdown,
  TextField
},
data () {
  return {
    categoryList: [],
    removeConfirm: false,
    sendObj: {
      itemCategoryMasterId: 0,
      categoryName: '',
      parentCategoryName: '',
      parentCategoryId: 0,
    },
    isErr: '',
    searchApitext: '',
  }
},
 created() {
},
mounted () {
  this.$root.$on('confirmBoxHandler', (response) => {
    if (response) {
      this.deleteApi()
    }
    document.body.style = 'overflow: visible;'
    this.removeConfirm = false
  })
  if (this.catId > 0) {
    this.getCatDetail()
  }
  this.$root.$on("clearInputAction", (response) => {
    this.sendObj.parentCategoryName = ''
    this.sendObj.parentCategoryId = 0
    console.log('responseresponseresponseresponseresponseresponseresponse', response);
  });
  this.getCatList()
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  callsearchCust(text) {
    this.searchApitext = text
    this.getCatList()
  },
  clearrrr() {
    console.log('clear 00000000000000>')
  },
  selectCatValue(data) {
    if (data !== null) {
      this.sendObj.parentCategoryId = data.itemCategoryMasterId
      this.sendObj.parentCategoryName = data.categoryName
    }
  },
  saveNewCustomer () {
    let isValid = true
    if (this.sendObj.categoryName.trim() === '') {
      isValid = false
      this.isErr = 'Category Name is required'
    }
    if (isValid) {
      console.log('call api');
       MyJobApp.SettingCategoryAddEdit(
       this.sendObj,
        response => {
          document.body.style = 'overflow: visible;'
          this.$root.$emit('closeDialog', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('closeDialog')
  },
  getCatList () {
      MyJobApp.getSettingCategoryList(
        0,
        20,
        this.searchApitext,
        '',
        '',
        response => {
          this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getCatDetail () {
      MyJobApp.SettingCategoryDetail(
      this.catId,
      response => {
        let responseA =  response.Data !== null ? response.Data : []
        this.sendObj.itemCategoryMasterId = responseA.itemCategoryMasterId
        this.sendObj.categoryName = responseA.categoryName
        this.sendObj.parentCategoryName = responseA.parentCategoryName
        this.sendObj.parentCategoryId = responseA.parentCategoryId
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
    },
    deleteApi () {
      MyJobApp.SettingCategoryDelete(
      this.catId,
      response => {
        console.log('response', response);
        this.$root.$emit('closeDialog', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  }
},
beforeDestroy () {
  this.$root.$off('clearInputAction')
  this.$root.$off('confirmBoxHandler')
}
}
</script>
<style scoped>

</style>