<template>
  <div>
    <div class=" col-span-2 mb-10 mt-4">
      <div class="card bg-white rounded-xl p-4">
        <div>
          <div class="">
            <TabCompo :tabsList="tabs" />
          </div>
          <div class="overflow-auto" :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`">
            <div v-if="activeIndex === 0" class="-mt-6">
             <ItemList />
            </div>
            <div v-if="activeIndex === 1" class="-mt-6">
             <CategoryList />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TabCompo from '@/View/components/tabs.vue'
import ItemList from '@/View/setting/items/list/itemList.vue'
import CategoryList from '@/View/setting/items/list/catogoryList.vue'
export default {
  name: "customer",
  components: {
    TabCompo,
    CategoryList,
    ItemList,
  },
  data() {
    return {
      cardHeight: 0,
      activeIndex: 0,
      contactDetailForUpdate: null,
      deleteSelectId: null,
      updatesContact: false,
      delteAddressConfirm: false,
      updateCompanyName: false,
      addAddress: false,
      custDetails: null,
      tabs: [
        {name: 'Items', active: true, canViewPermission: true},
        {name: 'Category', active: false, canViewPermission: true}
      ]
    };
  },
  created() {},
  mounted() {
    this.cardHeight = window.innerHeight - 200
    if (parseInt(this.$route.query.activeTab) >= 0) {
      this.tabsSet(parseInt(this.$route.query.activeTab))
      setTimeout(() => {
        this.$router.replace({'query': null});
      }, 500);
    }
    this.$root.$on('setNewTabActive', (index) => {
      this.tabsSet(index)
    })
  },
  methods: {
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
        if (index === i) {
          element.active = true
          this.activeIndex = i
        } else {
          element.active = false
        }
      });
    },
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
  },
};
</script>
<style scoped>
</style>