import { render, staticRenderFns } from "./addNewCatogery.vue?vue&type=template&id=531faa8c&scoped=true&"
import script from "./addNewCatogery.vue?vue&type=script&lang=js&"
export * from "./addNewCatogery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531faa8c",
  null
  
)

export default component.exports